.bubbles {
  width: 100% ; max-width: 2000px; height: 1600px; position: relative; margin: 0 auto;

  @media only screen and (max-width: 400px) {
    display: flex;
    flex-wrap: wrap;
    height: unset;

    & :global(.circle-size-100) {
      position: unset !important;
      width: 280px !important;
      height: 280px !important;

      &:nth-child(odd) {
        margin-left: auto;
      }

    }

    & :global(.circle-size-80) {
      position: unset !important;
      width: 260px !important;
      height: 260px !important;

      &:nth-child(odd) {
        margin-left: auto;
      }

    }

    & :global(.circle-size-60) {
      position: unset !important;
      width: 220px !important;
      height: 220px !important;

      &:nth-child(odd) {
        margin-left: auto;
      }

    }

  }

  & :global(.circle-inner) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-align: center;
    border-radius: 100%;
    padding: 34px;

    & :global(h2) {
      @apply text-white text-2xl md:text-2.5xl lg:text-5xl xl:text-6xl leading-6 mb-2;
    }

    & :global(p) {
      @apply text-xs md:text-sm lg:text-lg xl:text-lg text-white font-serif;
    }
  }

}
